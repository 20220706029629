@import "src/scss/module";

.title {
  text-align: left;

  &:has([data-sourceflow-path]:empty) {
    display: none;
  }
}


