@import "module";

#__next {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  width: 100%;
  margin: auto;
  min-height: 100vh;
  * {
    box-sizing: border-box;
  }

  & > main {
    font-size: 18px;
    color: #1B1B1B;
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  a {
    color: inherit;
    text-decoration: none;
  }
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

h1,
.h1 {
  margin-bottom: $spacer * 1.5;
  font-family: var(#{--bs-font-family-h1});
  font-weight: var(#{--bs-font-weight-h1});
  font-size: 56px;
  margin: 0;

  @media (max-width: 992px) {
    font-size: 48px;
  }
  
  @media (max-width: 768px) {
    font-size: 40px;
  }
  
  @media (max-width: 576px) {}
}

h2,
.h2 {
  font-family: var(#{--bs-font-family-h2});
  font-weight: var(#{--bs-font-weight-h2});
  margin: 0;
  font-size: 56px;

  @media (max-width: 992px) {
    font-size: 48px;
  }
  
  @media (max-width: 768px) {
    font-size: 40px;
  }
  
  @media (max-width: 576px) {}
}

h3,
.h3 {
  font-family: var(#{--bs-font-family-h3});
  font-weight: var(#{--bs-font-weight-h3});
  margin: 0;
}

h4,
.h4 {
  font-family: var(#{--bs-font-family-h4});
  font-weight: var(#{--bs-font-weight-h4});
  color: var(#{--bs-secondary});
  font-size: 32px;
  margin: 0;

  @media (max-width: 992px) {}
  
  @media (max-width: 768px) {
    font-size: 24px;
  }
  
  @media (max-width: 576px) {}
}

h5,
.h5 {
  font-family: var(#{--bs-font-family-h5});
  font-weight: var(#{--bs-font-weight-h5});
  font-size: 24px;
  margin: 0;

  @media (max-width: 992px) {}
  
  @media (max-width: 768px) {
    font-size: 18px;
  }
  
  @media (max-width: 576px) {}
}

h6,
.h6 {
  font-family: var(#{--bs-font-family-h6});
  font-weight: var(#{--bs-font-weight-h6});
  font-size: 18px;
  margin: 0;
}

body {
  font-family: var(#{--bs-font-family});
}

.overlay {
  opacity: 0.2;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;

  &.dark {
    opacity: 0.7;
  }

  &.primary {
    background-color: var(#{--bs-primary});
  }
  &.secondary {
    background-color: var(#{--bs-secondary});
  }
  &.tertiary {
    background-color: var(#{--bs-tertiary});
  }
  &.quaternary {
    background-color: var(#{--bs-quaternary});
  }
  &.white {
    background-color: var(#{--bs-white});
  }
  &.turquoise {
    background-color: var(#{--bs-turquoise});
  }
  &.yellow {
    background-color: var(#{--bs-yellow});
  }
  &.pink {
    background-color: var(#{--bs-pink});
  }
  &.purple {
    background-color: var(#{--bs-purple});
  }
  &.red {
    background-color: var(#{--bs-red});
  }
  &.blue {
    background-color: var(#{--bs-blue});
  }
  &.lilac {
    background-color: var(#{--bs-lilac});
  }
}

.highlight-span {
  &.primary {
    color: var(#{--bs-primary});
  }
  &.secondary {
    color: var(#{--bs-secondary});
  }
  &.tertiary {
    color: var(#{--bs-tertiary});
  }
  &.quaternary {
    color: var(#{--bs-quaternary});
  }
  &.white {
    color: var(#{--bs-white});
  }
  &.turquoise {
    color: var(#{--bs-turquoise});
  }
  &.yellow {
    color: var(#{--bs-yellow});
  }
  &.pink {
    color: var(#{--bs-pink});
  }
  &.purple {
    color: var(#{--bs-purple});
  }
  &.red {
    color: var(#{--bs-red});
  }
  &.blue {
    color: var(#{--bs-blue});
  }
  &.lilac {
    color: var(#{--bs-lilac});
  }
}

.fill {
  &.primary {
    fill: var(#{--bs-primary});
  }
  &.secondary {
    fill: var(#{--bs-secondary});
  }
  &.tertiary {
    fill: var(#{--bs-tertiary});
  }
  &.quaternary {
    fill: var(#{--bs-quaternary});
  }
  &.white {
    fill: var(#{--bs-white});
  }
  &.turquoise {
    fill: var(#{--bs-turquoise});
  }
  &.yellow {
    fill: var(#{--bs-yellow});
  }
  &.pink {
    fill: var(#{--bs-pink});
  }
  &.purple {
    fill: var(#{--bs-purple});
  }
  &.red {
    fill: var(#{--bs-red});
  }
  &.blue {
    fill: var(#{--bs-blue});
  }
  &.lilac {
    fill: var(#{--bs-lilac});
  }
}

.stroke {
  &.primary {
    stroke: var(#{--bs-primary});
  }
  &.secondary {
    stroke: var(#{--bs-secondary});
  }
  &.tertiary {
    stroke: var(#{--bs-tertiary});
  }
  &.quaternary {
    stroke: var(#{--bs-quaternary});
  }
  &.white {
    stroke: var(#{--bs-white});
  }
  &.turquoise {
    stroke: var(#{--bs-turquoise});
  }
  &.yellow {
    stroke: var(#{--bs-yellow});
  }
  &.pink {
    stroke: var(#{--bs-pink});
  }
  &.purple {
    stroke: var(#{--bs-purple});
  }
  &.red {
    stroke: var(#{--bs-red});
  }
  &.red {
    stroke: var(#{--bs-blue});
  }
  &.lilac {
    stroke: var(#{--bs-lilac});
  }
}

.bg {
  &.primary {
    background-color: var(#{--bs-primary});
  }
  &.secondary {
    background-color: var(#{--bs-secondary});
  }
  &.tertiary {
    background-color: var(#{--bs-tertiary});
  }
  &.quaternary {
    background-color: var(#{--bs-quaternary});
  }
  &.white {
    background-color: var(#{--bs-white});
  }
  &.turquoise {
    background-color: var(#{--bs-turquoise});
  }
  &.yellow {
    background-color: var(#{--bs-yellow});
  }
  &.pink {
    background-color: var(#{--bs-pink});
  }
  &.purple {
    background-color: var(#{--bs-purple});
  }
  &.red {
    background-color: var(#{--bs-red});
  }
  &.blue {
    background-color: var(#{--bs-blue});
  }
  &.lilac {
    background-color: var(#{--bs-lilac});
  }
}

.border-hover:hover {
  &.primary {
    border-color: var(#{--bs-primary});
  }
  &.secondary {
    border-color: var(#{--bs-secondary});
  }
  &.tertiary {
    border-color: var(#{--bs-tertiary});
  }
  &.quaternary {
    border-color: var(#{--bs-quaternary});
  }
  &.white {
    border-color: var(#{--bs-white});
  }
  &.turquoise {
    border-color: var(#{--bs-turquoise});
  }
  &.yellow {
    border-color: var(#{--bs-yellow});
  }
  &.pink {
    border-color: var(#{--bs-pink});
  }
  &.purple {
    border-color: var(#{--bs-purple});
  }
  &.red {
    border-color: var(#{--bs-red});
  }
  &.blue {
    border-color: var(#{--bs-blue});
  }
  &.lilac {
    border-color: var(#{--bs-lilac});
  }
}

.swiper-slide {
  display: flex !important;
}

button {
  background-color: transparent;

  &.btn-primary {
    border: solid 1px var(#{--bs-primary});
    color: var(#{--bs-primary});
    &:hover {
      background-color: var(#{--bs-primary});
      color: white;
      svg {
        fill: white;
      }
    }
  }
  &.btn-secondary {
    border: solid 1px var(#{--bs-secondary});
    color: var(#{--bs-secondary});
    &:hover {
      background-color: var(#{--bs-secondary});
      color: white;
      svg {
        fill: white;
      }
    }
  }
  &.btn-tertiary {
    border: solid 1px var(#{--bs-tertiary});
    color: var(#{--bs-tertiary});
    &:hover {
      background-color: var(#{--bs-tertiary});
      color: white;
      svg {
        fill: white;
      }
    }
  }
  &.btn-quaternary {
    border: solid 1px var(#{--bs-quaternary});
    color: var(#{--bs-quaternary});
    &:hover {
      background-color: var(#{--bs-quaternary});
      color: white;
      svg {
        fill: white;
      }
    }
  }
  &.btn-white {
    border: solid 1px var(#{--bs-white});
    color: var(#{--bs-white});
    &:hover {
      background-color: var(#{--bs-white});
      color: white;
      svg {
        fill: white;
      }
    }
  }
  &.btn-turquoise {
    border: solid 1px var(#{--bs-turquoise});
    color: var(#{--bs-turquoise});
    &:hover {
      background-color: var(#{--bs-turquoise});
      color: white;
      svg {
        fill: white;
      }
    }
  }
  &.btn-yellow {
    border: solid 1px var(#{--bs-yellow});
    color: var(#{--bs-yellow});
    &:hover {
      background-color: var(#{--bs-yellow});
      color: white;
      svg {
        fill: white;
      }
    }
  }
  &.btn-pink {
    border: solid 1px var(#{--bs-pink});
    color: var(#{--bs-pink});
    &:hover {
      background-color: var(#{--bs-pink});
      color: white;
      svg {
        fill: white;
      }
    }
  }
  &.btn-purple {
    border: solid 1px var(#{--bs-purple});
    color: var(#{--bs-purple});
    &:hover {
      background-color: var(#{--bs-purple});
      color: white;
      svg {
        fill: white;
      }
    }
  }
  &.btn-red {
    border: solid 1px var(#{--bs-red});
    color: var(#{--bs-red});
    &:hover {
      background-color: var(#{--bs-red});
      color: white;
      svg {
        fill: white;
      }
    }
  }
  &.btn-blue {
    border: solid 1px var(#{--bs-blue});
    color: var(#{--bs-blue});
    &:hover {
      background-color: var(#{--bs-blue});
      color: white;
      svg {
        fill: white;
      }
    }
  }
  &.btn-lilac {
    border: solid 1px var(#{--bs-lilac});
    color: var(#{--bs-lilac});
    &:hover {
      background-color: var(#{--bs-lilac});
      color: white;
      svg {
        fill: white;
      }
    }
  }
}

.button2 {
  width: 100%;
  display: flex;
  transition: $default-transition;
  justify-content: space-between;
  border: solid 1px transparent;
  color: white;

  &:hover {
    padding-left: 15%;
    transition: $default-transition;
  }

  &.bg-primary, &.bg-primary:hover {
    background-color: var(#{--bs-primary});
  }
  &.bg-secondary, &.bg-secondary:hover {
    background-color: var(#{--bs-secondary});
  }
  &.bg-tertiary, &.bg-tertiary:hover {
    background-color: var(#{--bs-tertiary});
  }
  &.bg-quaternary, &.bg-quaternary:hover {
    background-color: var(#{--bs-quaternary});
  }
  &.bg-white, &.bg-white:hover {
    background-color: var(#{--bs-white});
  }
  &.bg-turquoise, &.bg-turquoise:hover {
    background-color: var(#{--bs-turquoise});
  }
  &.bg-yellow, &.bg-yellow:hover {
    background-color: var(#{--bs-yellow});
  }
  &.bg-pink, &.bg-pink:hover {
    background-color: var(#{--bs-pink});
  }
  &.bg-purple, &.bg-purple:hover {
    background-color: var(#{--bs-purple});
  }
  &.bg-red, &.bg-red:hover {
    background-color: var(#{--bs-red});
  }
  &.bg-blue, &.bg-blue:hover {
    background-color: var(#{--bs-blue});
  }
  &.bg-lilac, &.bg-lilac:hover {
    background-color: var(#{--bs-lilac});
  }

  &.outline-primary, &.outline-primary:hover {
    border: solid 1px var(#{--bs-primary});
    color: var(#{--bs-primary});
  }
  &.outline-secondary, &.outline-secondary:hover {
    border: solid 1px var(#{--bs-secondary});
    color: var(#{--bs-secondary});
  }
  &.outline-tertiary, &.outline-tertiary:hover {
    border: solid 1px var(#{--bs-tertiary});
    color: var(#{--bs-tertiary});
  }
  &.outline-quaternary, &.outline-quaternary:hover {
    border: solid 1px var(#{--bs-quaternary});
    color: var(#{--bs-quaternary});
  }
  &.outline-white, &.outline-white:hover {
    border: solid 1px var(#{--bs-white});
    color: var(#{--bs-white});
  }
  &.outline-turquoise, &.outline-turquoise:hover {
    border: solid 1px var(#{--bs-turquoise});
    color: var(#{--bs-turquoise});
  }
  &.outline-yellow, &.outline-yellow:hover {
    border: solid 1px var(#{--bs-yellow});
    color: var(#{--bs-yellow});
  }
  &.outline-pink, &.outline-pink:hover {
    border: solid 1px var(#{--bs-pink});
    color: var(#{--bs-pink});
  }
  &.outline-purple, &.outline-purple:hover {
    border: solid 1px var(#{--bs-purple});
    color: var(#{--bs-purple});
  }
  &.outline-red, &.outline-red:hover {
    border: solid 1px var(#{--bs-red});
    color: var(#{--bs-red});
  }
  &.outline-blue, &.outline-blue:hover {
    border: solid 1px var(#{--bs-blue});
    color: var(#{--bs-blue});
  }
  &.outline-lilac, &.outline-lilac:hover {
    border: solid 1px var(#{--bs-lilac});
    color: var(#{--bs-lilac});
  }
}

.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  transition: all 200ms ease-in;

  &.primary {
    background: linear-gradient(to bottom, rgba(var(#{--bs-primary-rgb}), 0.2), var(#{--bs-primary}));
  }
  &.secondary {
    background: linear-gradient(to bottom, rgba(var(#{--bs-secondary-rgb}), 0.2), var(#{--bs-secondary}));
  }
  &.tertiary {
    background: linear-gradient(to bottom, rgba(var(#{--bs-tertiary-rgb}), 0.2), var(#{--bs-tertiary}));
  }
  &.quaternary {
    background: linear-gradient(to bottom, rgba(var(#{--bs-quaternary-rgb}), 0.2), var(#{--bs-quaternary}));
  }
  &.white {
    background: linear-gradient(to bottom, rgba(var(#{--bs-white-rgb}), 0.2), var(#{--bs-white}));
  }
  &.turquoise {
    background: linear-gradient(to bottom, rgba(var(#{--bs-turquoise-rgb}), 0.2), var(#{--bs-turquoise}));
  }
  &.yellow {
    background: linear-gradient(to bottom, rgba(var(#{--bs-yellow-rgb}), 0.2), var(#{--bs-yellow}));
  }
  &.pink {
    background: linear-gradient(to bottom, rgba(var(#{--bs-pink-rgb}), 0.2), var(#{--bs-pink}));
  }
  &.purple {
    background: linear-gradient(to bottom, rgba(var(#{--bs-purple-rgb}), 0.2), var(#{--bs-purple}));
  }
  &.red {
    background: linear-gradient(to bottom, rgba(var(#{--bs-red-rgb}), 0.2), var(#{--bs-red}));
  }
  &.blue {
    background: linear-gradient(to bottom, rgba(var(#{--bs-blue-rgb}), 0.2), var(#{--bs-blue}));
  }
  &.lilac {
    background: linear-gradient(to bottom, rgba(var(#{--bs-lilac-rgb}), 0.2), var(#{--bs-lilac}));
  }
}

.container:hover {
  * {
    transition: all 200ms ease-in;
  }

  .gradient-overlay {
    &.primary {
      background: linear-gradient(to bottom, rgba(var(#{--bs-primary-rgb}), 0.7), var(#{--bs-primary}));
    }
    &.secondary {
      background: linear-gradient(to bottom, rgba(var(#{--bs-secondary-rgb}), 0.7), var(#{--bs-secondary}));
    }
    &.tertiary {
      background: linear-gradient(to bottom, rgba(var(#{--bs-tertiary-rgb}), 0.7), var(#{--bs-tertiary}));
    }
    &.quaternary {
      background: linear-gradient(to bottom, rgba(var(#{--bs-quaternary-rgb}), 0.7), var(#{--bs-quaternary}));
    }
    &.white {
      background: linear-gradient(to bottom, rgba(var(#{--bs-white-rgb}), 0.7), var(#{--bs-white}));
    }
    &.turquoise {
      background: linear-gradient(to bottom, rgba(var(#{--bs-turquoise-rgb}), 0.7), var(#{--bs-turquoise}));
    }
    &.yellow {
      background: linear-gradient(to bottom, rgba(var(#{--bs-yellow-rgb}), 0.7), var(#{--bs-yellow}));
    }
    &.pink {
      background: linear-gradient(to bottom, rgba(var(#{--bs-pink-rgb}), 0.7), var(#{--bs-pink}));
    }
    &.purple {
      background: linear-gradient(to bottom, rgba(var(#{--bs-purple-rgb}), 0.7), var(#{--bs-purple}));
    }
    &.red {
      background: linear-gradient(to bottom, rgba(var(#{--bs-red-rgb}), 0.7), var(#{--bs-red}));
    }
    &.blue {
      background: linear-gradient(to bottom, rgba(var(#{--bs-blue-rgb}), 0.7), var(#{--bs-blue}));
    }
    &.lilac {
      background: linear-gradient(to bottom, rgba(var(#{--bs-lilac-rgb}), 0.7), var(#{--bs-lilac}));
    }
  }
}