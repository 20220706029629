@import "src/scss/module";

.header_padding {
  height: 121px;
  width: 100vw;
}

.button {
 display: flex;
 align-items: center;
 background-color: var(--bs-primary);
 color: white;
 padding: 14px 24px;
 border-radius: 24px;
 font-weight: bold;
 font-size: 18px;
 border: none;
 position: relative;
 margin: 0;
}

.dropDownMenu {
  position: absolute;
  min-width: 100%;
  background-color: var(--bs-primary);
  
  .dropDownDesc {
    display: flex;
    text-align: center;
    color: #fff;
    font-weight: 400;
    font-size: 16px;
    padding: 8px;
  }
}

.header {
  height: 108px;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  transition: $default-transition;
  z-index: 9;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 0 2vw;
    // border-radius: 12px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    transition: $default-transition;

    .toggle {
      cursor: pointer;
      transition: $default-transition;
    }

    .links {
      display: flex;
      align-items: center;
      gap: 1vw;

      .link {
        display: flex;
        align-items: center;
        background-color: var(#{--bs-primary});
        text-decoration: none;
        color: white;
        padding: 14px 24px;
        border-radius: 24px;
        gap: 12px;
        font-weight: bold;
        font-size: 18px;
        cursor: pointer;
        border: solid 1px white;
        transition: $default-transition;

        svg {
          flex-shrink: 0;
        }

        &:hover {
          background-color: var(#{--bs-primary-active});
          transition: $default-transition;
        }
      }
    }
  }

  &.scrolled {
    // padding: 12px 2vw;
    padding: 0;
    transition: $default-transition;

    .container {
      background-color: var(#{--bs-primary});
      padding: 0 3vw;
      transition: $default-transition;
    }
  }
}

// @media (max-width: 992px) {}

@media (max-width: 992px) {
  .header {
    .container {
      .links {
        gap: 1vw;

        .link {
          padding: 8px 16px;
          gap: 6px;
          font-size: 16px;

          svg {
            transform: scale(0.8);
          }
        }
      }
    }

    &.scrolled {
      //padding: 12px 1vw;

      .container {
        padding: 0 2vw;
      }
    }
  }
}

@media (max-width: 767px) {
  .header {
    .container {
      .links {
        gap: 12px;

        .link {
          width: 40px;
          height: 40px;
          padding: 0;

          .desc {
            display: none;
          }

          svg {
            transform: scale(1);
            margin: auto;
          }
        }
      }
    }

    &.scrolled {
      //padding: 12px 2vw;

      .container {
        padding: 0 7vw;
      }
    }
  }
}