// Fonts
@font-face {
    font-family: 'Calibri';
    src: url('./fonts/calibril.ttf') format('truetype');
    font-style: normal;
}

@font-face {
    font-family: 'Calibri';
    src: url('./fonts/calibri-bold.ttf') format('truetype');
    font-style: normal;
    font-weight: bold;
}

@font-face {
    font-family: 'Poppins';
    src: url('./fonts/Poppins-Light.ttf') format('truetype');
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('./fonts/Poppins-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: 'Poppins';
    src: url('./fonts/Poppins-Bold.ttf') format('truetype');
    font-style: normal;
    font-weight: bold;
}

$font-family: 'Calibri', sans-serif;
$secondary-font: 'Poppins', sans-serif;

// Colours
$primary-color: #CB0666;
$secondary-color: #4A2E49;
$tertiary-color: #927D81;
$quarternary-color: #B2C2BA;

$bg-dark-100: #0f0e1148;
$bg-dark-90: #5C425B;
$bg-dark-80: #6C566B;
$bg-dark-70: #7E6A7D;
$bg-dark-60: #8E7E8E;
$bg-dark-50: #9F919E;
$bg-light-40: #B1A5B0;
$bg-light-30: #C2BAC2;
$bg-light-20: #D3CDD3;
$bg-light-10: #E4E2E4;
$bg-light-5: #EDEBED;
$bg-light-2: #F1F1F1;

$base-white: #ffffff;
$base-black: #000000;
$font-color: #2a2a2a;

// Links
$link-color: $primary-color;
$link-color-hover: darken($link-color, 5%);
$link-decoration: none;
$link-decoration-hover: none;

// Social
$linkedin: #0a66c2;
$facebook: #3b5998;
$twitter: #1da1f2;
$youtube: #ff0000;
$instagram: #c32aa3;
$whatsapp: #25d366;

// Breakpoints
$breakpoint-xlg: 1929px;
$breakpoint-xxxl: 1599px;
$breakpoint-xxl: 1399px;
$breakpoint-xl: 1199px;
$breakpoint-lg: 991px;
$breakpoint-md: 767px;
$breakpoint-sm: 566px;



$default-transition: all 200ms ease-in;